import React from "react"
import PropTypes from "prop-types"
import ABOMS from "./logo-ABOMS.svg"
import SocialReviews from "../../components/SocialReviews/SocialReviews"

function ContentColumn({ heading, topBlurb, language, badge }) {
  let docName = heading
  docName = addNewlineAfterString(docName, "Meet")

  return (
    <div className="column is-10 doctor-top-intro">
      <div
        style={{ whiteSpace: "pre-wrap" }}
        className="doctor-top-intro-inner"
      >
        <h1>{docName}</h1>
        <p className="large-doctor-text large">{topBlurb}</p>
        <div className="rating-review columns is-vcentered">
          <div className="column">
            <SocialReviews google isCentered language={language} />
          </div>
          {badge && badge.hasBadge && (
            <>
              <div className="column is-3"></div>
              <div className="column is-6">
                <img className="badge" src={ABOMS} />
              </div>
              <div className="column is-3"></div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

ContentColumn.propTypes = {
  heading: PropTypes.string.isRequired,
  topBlurb: PropTypes.string.isRequired,
  language: PropTypes.string,
  badge: PropTypes.object,
  hasBadge: PropTypes.bool
}

export default ContentColumn

function addNewlineAfterString(/*in/out*/ text, /*in*/ str) {
  return text.replace(`${str} `, `${str}\n`)
}
